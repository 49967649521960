import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

// Import components
import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBanner from "../components/title-banner"

const Sens = () => {
  const data = useStaticQuery(SENS)
  const imageData = data?.allImageSharp?.nodes || []
  return (
    <Layout>
      <Seo title="Home" />
      <TitleBanner imageData={imageData}>SENS</TitleBanner>
    </Layout>
  )
}

export default Sens

const SENS = graphql`
  {
    allImageSharp(
      filter: {
        fixed: {
          originalName: {
            in: [
              "banner-image-integrated-reports.png"
              "banner-image-integrated-reports-mobile.png"
            ]
          }
        }
      }
      sort: { fields: fixed___originalName, order: DESC }
    ) {
      nodes {
        gatsbyImageData
      }
    }
  }
`
